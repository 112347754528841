import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Home from './views/Home.vue'
import Services from './views/Services.vue';
import Notices from './views/Notices.vue';
//import Post from './views/Post.vue';
import ElPuerto from './views/ElPuerto.vue';
import Politica from './views/Politica.vue';
import Gallery from './views/Gallery.vue';
import Contact from './views/Contact.vue';
import NotFound from './views/NotFound.vue';


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/servicios',
      name: 'services',
      component: Services
    },
    {
      path: '/noticias',
      name: 'notices',
      component: Notices
    },
    // {
    //   path: '/noticia/:title/:key',
    //   name: 'post',
    //   component: Post
    // },
    {
      path: '/el-puerto',
      name: 'el-puerto',
      component: ElPuerto
    },
    {
      path: '/politica',
      name: 'politica',
      component: Politica
    },
    {
      path: '/galeria',
      name: 'gallery',
      component: Gallery
    },
    {
      path: '/contacto',
      name: 'contact',
      component: Contact
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    }
  ]
})

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
	setTimeout(()=>{
		store.commit('setSplashScreen', false)
	}, 4000)
})

export default router;
