<template>
  <section id="main-galeria">
    <div class="container">
      <div class="row">
        <h1 class="sub-titulo orange-text text-accent-4 center-align">{{ $t('gallery.title') }}</h1>
        

        <masonry :cols="{default: 3, 1200: 3, 1000: 2, 480: 1}" :gutter="20">
          <div class="item card-base card-alt" 
            v-for="(item,i) in _images" 
            :key="i"
          >
            <img v-if="item.url" :src="`${$store.state.apiURL}${item.url}`" alt="card image">
          </div>
        </masonry>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Gallery",
  data() {
    return {
      list_images: [
        {img: '/static/images/galeria/1-min.jpg'},
        {img: '/static/images/galeria/2-min.jpg'},
        {img: '/static/images/galeria/3-min.jpg'},
        {img: '/static/images/galeria/4-min.jpg'},
        {img: '/static/images/galeria/5-min.jpg'},
      ]
    };
  },
  mounted(){
    // var elem = document.querySelector('.m-p-g');
    // var gallery = new MaterialPhotoGallery(elem);
  },
  computed: {
    _images() {
      const gallery = this.$store.getters["content"].gallery;
      const data = [];
      if (gallery) {
        Object.keys(gallery).forEach(k => {
          data.push(gallery[k]);
        });
      }
      return data;
    }
  },
};
</script>
<style>
.item {
  /* padding: 20px; */
  /* margin: 20px 0; */
  margin-bottom: 20px;
  /* border-radius: 5px; */
  opacity: .85;
  transition: all .25s;

  /* masonry */
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.item:hover {
  opacity: 1;
  /* box-shadow: 0px 0px 0px 4px; */
}
.item img {
  width: 100%;
  max-width: 100%;
}
</style>
