<template>
  <div>
    <section id="main-contact" class="contact">
      <div class="container">
        <div class="row">
          <div class="col s12 m6 l5">
            <h1 class="sub-titulo left-align orange-text text-accent-4">{{ $t('contacts.title') }}</h1>
            <div class="col s12" v-if="_contacts.emails.length > 0">
              <a :href="`mailto:${_contacts.emails[0].label}`" target="_blank" class="left-align">
                <span>Comercial:</span>
                {{ _contacts.emails[0].label }}
              </a>
            </div>
            <div class="col s12">
              <a href="mailto:reclamos@psf.com.py" target="_blank" class="left-align">
                <span>Reclamos:</span> reclamos@psf.com.py
              </a>
            </div>
            <div class="col s12">
              <a href="mailto:rrhh@psf.com.py" target="_blank" class="left-align">
                <span>Unite al equipo PSF:</span> rrhh@psf.com.py
              </a>
            </div>
            <div class="col s12" v-if="_contacts.phones.length > 0">
              <a :href="`tel:${_contacts.phones[0].label}`" target="_blank" class="left-align">
                <span>Call center:</span>
                {{ _contacts.phones[0].label }}
              </a>
              <a :href="`tel:${_contacts.phones[1].label}`" target="_blank" class="left-align">
                <span>Adm. Aduana:</span>
                {{ _contacts.phones[1].label }}
              </a>
            </div>

            <!-- <div class="col s12">
              <a href="#" target="_blank" class="left-align">
                <span>Dirección:</span> Ruta Villeta - Alberdi KM. 4,5
              </a>
            </div>-->
            <div class="col s12 left-align">
              <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                <symbol id="instagram" viewBox="0 0 512 512">
                  <title>instagram</title>
                  <g>
                    <g>
                      <path
                        d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160 C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48 h192c61.76,0,112,50.24,112,112V352z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336 c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <circle cx="393.6" cy="118.4" r="17.056" />
                    </g>
                  </g>
                </symbol>
                <symbol id="whatsapp-logo-variant" viewBox="0 0 30.667 30.667">
                  <title>whatsapp-logo-variant</title>
                  <path
                    d="M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017 c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382 c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076 c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427 c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437 c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536 c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609 c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611 c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787 c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739 C23.307,19.268,23.307,18.533,23.214,18.38z"
                  />
                </symbol>
                <symbol id="facebook" viewBox="0 0 512 512">
                  <title>facebook</title>
                  <path
                    d="M452,0H60C26.916,0,0,26.916,0,60v392c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V60 C512,26.916,485.084,0,452,0z M472,452c0,11.028-8.972,20-20,20H338V309h61.79L410,247h-72v-43c0-16.975,13.025-30,30-30h41v-62 h-41c-50.923,0-91.978,41.25-91.978,92.174V247H216v62h60.022v163H60c-11.028,0-20-8.972-20-20V60c0-11.028,8.972-20,20-20h392 c11.028,0,20,8.972,20,20V452z"
                  />
                </symbol>
              </svg>
              <ul class="redes">
                <li>
                  <a href target="_blank">
                    <svg class="icon">
                      <use xlink:href="#instagram" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href target="_blank">
                    <svg class="icon">
                      <use xlink:href="#facebook" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href target="_blank">
                    <svg class="icon">
                      <use xlink:href="#whatsapp-logo-variant" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col s12 m6 l7">
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3591.725835147281!2d-57.55234267189808!3d-25.54821871604265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945c56b45051f655%3A0x9f3ea35a8984c4e7!2sPuerto+Seguro+Fluvial+S.A.!5e0!3m2!1ses-419!2spy!4v1559244984683!5m2!1ses-419!2spy"
                frameborder="0"
                style="border:0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <contacto-component></contacto-component>
  </div>
</template>
<script>
import ContactoComponent from "@/components/home/Contacto.vue";
export default {
  name: "Contacto",
  components: { ContactoComponent },
  computed: {
    _contacts() {
      const contacts = this.$store.getters["content"].contacts;
      const data = {
        phones: [],
        emails: [],
        address: [],
        // schedules: [],
      };
      if (contacts) {
        Object.keys(contacts).forEach((k) => {
          switch (contacts[k].type) {
            case "email":
              data.emails.push({
                label: `${contacts[k].detail}`,
              });
              break;
            case "number":
              data.phones.push({
                label: `${contacts[k].detail}`,
              });
              break;
            case "address":
              data.emails.push({
                link: "https://goo.gl/maps/TqEH5TCHH2AURubF8",
                label: `${contacts[k].detail}`,
              });
              break;
            // case 'schedule':
            //   data.emails.push({
            //     link: `mailto:${contacts[k].detail}`,
            //     label: 'footer.send_email'
            //   });
            //   break;
            // default:
            //   break;
          }
        });
      }
      return data;
    },
  },
};
</script>
