<template>
  <div>
    <ul id="menu-lang" class="dropdown-content">
      <li v-for="(item,i) in dropdown" :key="i">
        <a href="" @click.prevent="changeLanguage(item)">
          {{ $t(item.detail)}}
        </a>
      </li>
    </ul>
    <ul id="menu-lang-mb" class="dropdown-content" >
      <li v-for="(item,i) in dropdown" :key="i">
        <a href="" @click.prevent="changeLanguage(item)">
          {{ $t(item.detail)}}
        </a>
      </li>
    </ul>
     <ul id="el-puerto-drop-mb" class="dropdown-content" >
      <li >
        <a href="" @click.prevent="goTo('politica')">
          {{ $t('politica.title')}}
        </a>
      </li>
    </ul>
    <ul id="el-puerto-drop" class="dropdown-content">
      <li >
        <a href="" @click.prevent="goTo('politica')">
          {{ $t('politica.title')}}
        </a>
      </li>
    </ul>
    <div class="navbar-fixed">
      <nav class="nav-bg" id="nav">
        <div :class="`nav-wrapper ${currentRoute === 'home' ? 'nav-bg' : 'nav-bg-2'}`" id="nav-child">
          <div class="container">
            <a href="" @click.prevent="$router.push('/')" class="brand-logo ">
              <div id="logo-principal"></div>
            </a>
            <a href="#" data-target="slide-out" class="sidenav-trigger show-on-medium-and-down"><i class="material-icons">menu</i></a>
            <ul id="nav-mobile" class="right hide-on-med-and-down">
              <!-- <li v-for="(item,i) in list" :key="i">
                <a href="" @click.prevent="goTo(item.link)" class="">{{ $t(item.label) }}</a>
              </li> -->
              <li>
                <a href="" @click.prevent="goTo('home')">{{ $t('nav.home') }}</a>
              </li>
               <li>
                <a href="" @click.prevent="goTo('services')">{{ $t('nav.services') }}</a>
              </li>
               <li>
                <a class="dropdown-trigger" href="#!" data-target="el-puerto-drop" @click.prevent="goTo('el-puerto')">{{ $t('nav.aboutUs') }}<i class="material-icons right">arrow_drop_down</i></a>
              </li>
               <li>
                <a href="" @click.prevent="goTo('notices')">{{ $t('nav.news') }}</a>
              </li>
               <li>
                <a href="" @click.prevent="goTo('contact')">{{ $t('nav.contact') }}</a>
              </li>
              <li>
                <a class="dropdown-trigger" href="#" data-target="menu-lang">
                  {{ $t(active.detail) }}<i class="material-icons right">arrow_drop_down</i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <ul id="slide-out" class="sidenav orange accent-4 ">
      <li>
        <div class="user-view white center-align">
          <img src="/static/images/logo/logo-original.png">
        </div>
      </li>
       <li>
                <a  class="white-text" href="" @click.prevent="goTo('home')"><i class="material-icons">{{ 'home' }}</i>{{ $t('nav.home') }}</a>
              </li>
               <li>
                <a  class="white-text" href="" @click.prevent="goTo('services')"><i class="material-icons">{{ 'done' }}</i>{{ $t('nav.services') }}</a>
              </li>
               <li>
                <a class="dropdown-trigger white-text" href="#!" data-target="el-puerto-drop-mb" @click.prevent="goTo('el-puerto')"><i class="material-icons">{{ 'directions_boat'}}</i>{{ $t('nav.aboutUs') }}</a>
              </li>
               <li>
                <a  class="white-text" href="" @click.prevent="goTo('notices')"><i class="material-icons">{{ 'info' }}</i>{{ $t('nav.news') }}</a>
              </li>
               <li>
                <a  class="white-text" href="" @click.prevent="goTo('contact')"><i class="material-icons">{{ 'call' }}</i>{{ $t('nav.contact') }}</a>
              </li>
      <!-- <li v-for="(item,i) in list" :key="i">
        <a href="" @click.prevent="goTo(item.link)" class="white-text"><i class="material-icons">{{ item.icon }}</i>{{ $t(item.label) }}</a>
      </li> -->
      <li>
        <a class="dropdown-trigger white-text" href="#" data-target="menu-lang-mb">
          {{ $t(active.detail) }}<i class="material-icons right">arrow_drop_down</i>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  data(){
    return {
      list: [
        {link: 'home', label: 'nav.home', icon: 'home', delay: '2.5s'},
        {link: 'services', label: 'nav.services', icon: 'done', delay: '2.6s'},
        {link: 'el-puerto', label: 'nav.aboutUs', icon: 'directions_boat', delay: '2.7s'},
        {link: 'notices', label: 'nav.news', icon: 'info', delay: '2.8s'},
        {link: 'contact', label: 'nav.contact', icon: 'call', delay: '2.9s'}
      ],
      active: {
        detail: '',
        value: ''
      },
      languages: ['es', 'en'],
      dropdown: [
        {
          detail: 'languages.English',
          // icon: '/static/images/brazil.svg',
          value: 'en'
        },
        {
          detail: 'languages.Spanish',
          // icon: '/static/images/spain.png',
          value: 'es'
        }
      ]
    }
  },
  computed: {
    currentRoute(){
      return this.$route.name;
    }
  },
  mounted(){
    const current_lang = this.$store.getters['language'];
    this.active = this.dropdown.filter(e => e.value === current_lang)[0];
  },
  methods: {
    goTo(name){
      this.$router.push({name});
    },
    changeLanguage(e){
      if(this.languages.indexOf(e.value) !== -1){
        this.active = e;
        this.$store.commit('setLanguage', e.value);
        this.$i18n.locale = this.active.value;
      }
    }
  }
}
</script>
<style >
#el-puerto-drop, #menu-lang{ 
  height:auto !important; 
  width:220px !important; 
  margin-top:84px !important;
  top: 0 !important;
  }
  #menu-lang{ width:130px !important;}
</style>