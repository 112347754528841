<template>
  <div>
    <section class="contenido institucional orange accent-4">
      <div class="container center-align">
        <div class="row">
          <h2 class="sub-titulo white-text">{{ $t('about_us.title') }}</h2>
          <div class="col s12 m10 l8 offset-m1 offset-l2">
            <div class="video-container">
              <iframe src="https://www.youtube.com/embed/03OlRJZ2C-0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="row center-align caracteristicas">
          <div class="col s6 m4 l2" v-for="(item, i) in caracteristicas" :key="i">
            <img :src="item.img" alt="caracteristicas" />
          </div>
        </div>
        <div class="row left-align">
          <p class="white-text">{{ _aboutUs.description }}</p>
        </div>
        <div class="row left-align">
          <h3 class="sub-titulo2 white-text">{{ $t('about_us.mision_title') }}</h3>
          <p class="white-text myvp">{{ $t('about_us.mision_description') }}</p>
        </div>
        <div class="row left-align">
          <h3 class="sub-titulo2 white-text">{{ $t('about_us.vision_title') }}</h3>
          <p class="white-text myvp">{{ $t('about_us.vision_description') }}</p>
        </div>
      </div>
    </section>
    <section class="white" id="detalle_del_puerto">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion1.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/el-muelle.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ $t('about_us.seccion1.description')}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion2.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/predio.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ $t('about_us.seccion2.description')}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion3.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/andenes.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ $t('about_us.seccion3.description')}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion4.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/estacionamiento.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ $t('about_us.seccion4.description')}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion5.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/aduanas.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ $t('about_us.seccion5.description')}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion6.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/maquinarias.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p v-html="$t('about_us.seccion6.description')"></p>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4">{{ $t('about_us.seccion7.title')}}</h2>
          </div>
          <div class="col s12 m12 l6">
            <img src="/static/images/el-puerto/talleres.jpg" alt class="responsive-img" />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ $t('about_us.seccion7.description')}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "El-Puerto",
  data() {
    return {
      caracteristicas: [
        { img: "/static/images/servicios/c-zona.png" },
        { img: "/static/images/servicios/c-100.png" },
        { img: "/static/images/servicios/c-flota.png" },
        { img: "/static/images/servicios/c-talleres.png" },
        { img: "/static/images/servicios/c-grande.png" },
        { img: "/static/images/servicios/c-maquinaria.png" }
      ]
    };
  },
  computed: {
    _aboutUs() {
      const lang = this.$store.getters["language"];
      return this.$store.getters["content"][lang]["el-puerto"];
    }
  }
};
</script>
<style>
#detalle_del_puerto .sub-titulo {
  margin-top: 50px;
  font-size: 25px;
}
iframe{
  width: 100%;
  min-height: 400px;
}
.sub-titulo2{
  letter-spacing: 5px;
    font-size: 28px;
    margin-top: 0px;
    font-weight: 700;
    text-transform: uppercase;
} 
.myvp{
      margin-top: 0px !important;
}
</style>
