<template>
  <span id="app">
    <transition name="fade">
       <div id="pageloader" v-if="splashScreen"></div>
    </transition>
    <menu-component/>
    <transition>
      <router-view/>
    </transition>
    <footer-component/>
    <notifications group="form-message" />
  </span>
</template>

<script>
import MenuComponent from './components/core/Menu.vue';
import FooterComponent from './components/core/Footer.vue';

export default {
  name: 'app',
  components: {MenuComponent, FooterComponent},
  data () {
    return {
    }
  },
  metaInfo () {
    return {
      // title: this.notice.title,
      meta: [
        {
          'http-equiv': 'X-UA-Compatible',
          'content': 'IE=edge'
        },
        {
          'name': 'viewport',
          'content': 'width=device-width, initial-scale=1.0 user-scalable=no'
        },
        {
          'name': 'author',
          'content': 'Puerto Seguro Fluvial'
        },
        {
          'name': 'description',
          'content': 'Una década creando lazos con nuestros clientes y colaboradores, a través de soluciones aplicadas en cada operativa, con la logística más tecnológica y la agilidad del trato diferenciado para cada carga.',
          'vmid': 'description'
        },
        {
          'name': 'keywords',
          'content': 'puerto seguro, puerto seguro fluvial, puerto, solucion logistica, logistica, transporte, flete, almacenamiento'
        },
        {
          'name': 'robots',
          'content': 'index, follow'
        },
        {
          'property': 'og:type',
          'content': 'website',
          'vmid': 'og:type'
        },
        {
          'property': 'og:image',
          'content': '/static/images/bg/home-bg.jpg',
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:type',
          'content': 'image/jpg',
          'vmid': 'og:image:type'
        },
        {
          'property': 'og:image:width',
          'content': '1200',
          'vmid': 'og:image:width'
        },
        {
          'property': 'og:image:height',
          'content': '630',
          'vmid': 'og:image:height'
        },
        {
          'property': 'og:title',
          'content': 'Puerto Seguro Fluvial',
          'vmid': 'og:title'
        },
        {
          'property': 'og:url',
          'content': 'https://puertosegurofluvial.com/',
          'vmid': 'og:url'
        },
        {
          'property': 'og:site_name',
          'content': 'Puerto Seguro Fluvial',
          'vmid': 'og:site_name'
        },
        {
          'property': 'og:description',
          'content': 'Una década creando lazos con nuestros clientes y colaboradores, a través de soluciones aplicadas en cada operativa, con la logística más tecnológica y la agilidad del trato diferenciado para cada carga.',
          'vmid': 'og:description'
        }
      ]
    }
  },
  mounted(){
    this.$store.dispatch('getContent').then(res => {
      // You'll need this for renderAfterDocumentEvent.
      // if(this.$route.name !== 'post') document.dispatchEvent(new Event('render-event'));
      document.dispatchEvent(new Event('render-event'));
    });
    window.scroll({
      top:0,
      left:0,
      behavior: 'smooth'
    });
  },
  computed: {
    splashScreen(){
      return this.$store.getters['splashScreen']
    }
  }
}
</script>
<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
  }
  /* .fade-top-in-out-enter { opacity: 0; transform: translate(0, 30px); }
  .fade-top-in-out-leave-active { opacity: 0; transform: translate(0, 30px); }

  .fade-top-enter { opacity: 0; transform: translate(0, 30px); }
  .fade-top-leave-active { opacity: 0; transform: translate(0, -30px); } */
</style>
