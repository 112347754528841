<template>
  <section id="tracking" class="grey lighten-5">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <h2 class="sub-titulo orange-text text-accent-4 left-align">{{ $t('notices.title') }}</h2>
        </div>
        <div class="col s12 m6 l4" v-for="item in _notices" :key="item.key">
          <notice-card 
            :title="item.title"
            :description="item.description"
            :img="`${$store.state.apiURL}${item.img}`"
            :id="item.key"
          ></notice-card>
        </div>
        <div class="col s12 center-align" style="padding-top:40px" v-show="_notices.length > 0">
          <a
            href
            @click.prevent="goToNotices()"
            class="waves-effect waves-light boton boton-orange transparent"
          >{{ $t('notices.see_more') }}</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import NoticeCard from '@/components/NoticeCard.vue';
export default {
  name: "Home-Notices",
  components: {NoticeCard},
  data() {
    return {};
  },
  computed: {
    _notices(){
      const lang = this.$store.getters['language'];
      const notices = this.$store.getters['content'][lang].notices;
      const data = [];
      if(notices){
        Object.keys(notices).forEach(k => {
          notices[k].key = k;
          data.push(notices[k]);
        });
      }
      
      return data;
    }
  },
  methods: {
    formatTitle(title){
      return title.replace(/[ /\\]/g, '_');
    },
    goToNotices(){
      this.$router.push({name: 'notices'});
    }
  }
};
</script>
<style>
#noticias-details .card-content, #tracking .card-content{
  height: 160px;
  overflow-y: hidden;
}
#noticias-details  .card-image, #tracking .card-image{
  height: 200px;
}
</style>