<template>
  <section id="contador">
    <div class="container center-align">
      <h2 class="sub-titulo white-text">{{ $t('home.contador_title') }}</h2>
      <div class="row">
        <div class="col s6 m4 l2 wow fadeIn" data-wow-delay=".5s" v-for="(item,i) in list" :key="i">
          <img :src="item.img" alt="contador" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Home-Contador',
  data(){
    return {
      list: [
        {img: '/static/images/servicios/c-zona.png'},
        {img: '/static/images/servicios/c-100.png'},
        {img: '/static/images/servicios/c-maquinaria.png'},
        {img: '/static/images/servicios/c-flota.png'},
        {img: '/static/images/servicios/c-talleres.png'},
        {img: '/static/images/servicios/c-grande.png'},
      ]
    }
  },
  computed: {
    _contador() {
      const contador = this.$store.getters["content"].contador;
      const data = [];
      return data;
    }
  }
}
</script>
