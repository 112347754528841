<template>
  <span>
    <div id="main-noticias">
      <div class="container">
        <div class="row">
          <div class="col s12 left-align">
            <h2 class="sub-titulo white-text">{{ $t('notices.title') }}</h2>
          </div>
        </div>
      </div>
    </div>
    <section id="noticias-details" class="grey lighten-5">
      <div class="container">
        <div class="row">
          <div class="col s12 m6 l4" v-for="item in _notices" :key="item.key">
            <notice-card
              :title="item.title"
              :description="item.description"
              :img="`${$store.state.apiURL}${item.img}`"
              :id="item.key"
            ></notice-card>
          </div>
        </div>
      </div>
    </section>
    <section class="white" id="enlaces">
      <div class="container" id="interes">
        <div class="row">
           <h3>{{ $t('notices.subtitle1') }}</h3>
          <div class="col s12 m6 l4">
            <a href="http://www.aduana.gov.py/" target="_blank">Dirección Nacional de Aduanas (DNA)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.mic.gov.py/mic/site/inicio.php"
              target="_blank"
            >Ministerio de Industria y Comercio (MIC)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.hacienda.gov.py/web-hacienda/index.php"
              target="_blank"
            >Ministerio de Hacienda (MH)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.senacsa.gov.py/web/"
              target="_blank"
            >Servicio Nacional de Calidad y Salud Animal (SENACSA)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.senave.gov.py/"
              target="_blank"
            >Servicio Nacional de Calidad y Sanidad Vegetal y de Semilla (SENAVE)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.intn.gov.py/intn/"
              target="_blank"
            >Instituto Nacional de Tecnología y Normalización (INTN)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.mspbs.gov.py/v3/"
              target="_blank"
            >Ministerio de Salud Pública y Bienestar Social (MSPBS)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.paraguay.gov.py/infona"
              target="_blank"
            >Instituto Forestal Nacional (INFONA)</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.senad.gov.py/"
              target="_blank"
            >Secretaría Nacional Antidrogas (SENAD)</a>
          </div>
          <div class="col s12 m6 l4">
            <a href="http://municipalidadvilleta.gov.py/" target="_blank">Municipalidad de Villeta</a>
          </div>
          <div class="col s12 m6 l4">
            <a href="www.cip.org.py" target="_blank">Centro de Importadores del Paraguay</a>
          </div>
          <div class="col s12 m6 l4">
            <a
              href="http://www.annp.gov.py/"
              target="_blank"
            >Administración Nacional de Navegación y Puertos</a>
          </div>
          <div class="col s12 m6 l4">
            <a href="http://www.uip.org.py/" target="_blank">Unión Industrial Paraguaya</a>
          </div>
        </div>
        <div class="row">
          <h3>{{ $t('notices.subtitle2') }}</h3>
          <div class="col s12 m4 l3" v-for="(lis,i) in list" :key="i" style="text-align:center">
            <a :href="lis.link" target="_blank">
                <img :src="`${lis.img}`"  />
              </a>
          </div>
        </div>
      </div>
    </section>
  </span>
</template>
<script>
import NoticeCard from "@/components/NoticeCard.vue";
export default {
  name: "Home-Noticias",
  components: { NoticeCard },
  data() {
    return {
      list: [
        {img:'https://puertosegurofluvial.com/api_public/img/clients/one-min_06082019131255.jpeg', link: 'https://www.one-line.com/'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/msc-min_06082019131305.jpeg', link: 'https://www.msc.com/pry'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/cma-min_06082019131329.jpeg', link: 'https://www.cma-cgm.com/'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/zim-min_06082019131312.jpeg', link: 'https://www.zim.com/'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/c2_24072019100158.jpeg', link: 'https://www.hamburgsud-line.com/liner/en/liner_services/index.html'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/c3_24072019100253.jpeg', link: 'https://www.hapag-lloyd.com/es/home.html'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/evergreen-min_06082019130356.jpeg', link: 'https://www.evergreen-line.com/'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/grimaldi-min_06082019131321.jpeg', link: 'https://www.grimaldi-lines.com/es'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/c4_24072019100323.jpeg', link: 'https://www.maersk.com/'},
        {img:'https://puertosegurofluvial.com/api_public/img/clients/yangming-min_06082019130404.jpeg', link: 'https://www.yangming.com/'}
      ]
    };
  },
  computed: {
    _notices() {
      const lang = this.$store.getters["language"];
      const notices = this.$store.getters["content"][lang].notices;
      const data = [];
      if (notices) {
        Object.keys(notices).forEach(k => {
          notices[k].key = k;
          data.push(notices[k]);
        });
      }

      return data;
    }
  },
  methods: {}
};
</script>
<style>
#noticias-details .card-content {
  height: 160px;
  overflow-y: hidden;
}
#noticias-details .card-image {
  height: 200px;
}
#interes h3 {
  font-size: 18px;
  font-weight: bolder;
}
#interes a{
  display: block;
  margin-bottom: 5px;
}
#interes a:hover{
  color: #333333;
}
#interes img{
  width: 70%;
}
#enlaces{
  padding-bottom: 0px;
}
</style>
