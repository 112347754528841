<template>
  <section id="galeria-fotos" class="grey lighten-1">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <h2 class="sub-titulo white-text center-align">{{ $t('gallery.title') }}</h2>
          <viewer :images="_images">
            <masonry :cols="{default: 3, 1200: 3, 1000: 2, 480: 1}" :gutter="20">
              <div class="item card-base card-alt" 
                v-for="(item,i) in _images" 
                :key="i"
              >
                <img v-if="item" :src="`${$store.state.apiURL}${item}`" alt="Imagen de galeria">
              </div>
            </masonry>
          </viewer>
        </div>
      </div>
    </div>
  </section>
</template>
<script>


export default {
  name: "Home-Galeria",
  data() {
    return {
    };
  },
  computed: {
    _images() {
      const gallery = this.$store.getters["content"].gallery;
      const data = [];
      if (gallery) {
        Object.keys(gallery).forEach(k => {
          data.push(gallery[k].url);
        });
      }
      return data;
    }
  },
};
</script>
<style scoped>

.item {
  margin-bottom: 20px; 
  opacity: .85;
  transition: all .25s;
  border-width: 0 !important;
 cursor: pointer;
  /* masonry */
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.item:hover {
  opacity: 1;
}
.item img {
  width: 100%;
  max-width: 100%;
}
.images-list {
  overflow: hidden;
  margin: -1%;
}
.images-list .box-margins {
  margin: 1%;
}
/* img {
  cursor: pointer;
  width: 18%;
  border-radius: 10px;
  
  float: left;
  box-sizing: border-box;
  margin: 1%;
  border: 5px solid #fff;
  box-shadow: 0px 7px 20px -7px rgba(0, 0, 0, 0.55);
  transition: all .5s;
}
img:hover {
  box-shadow: none;
  transform: translateY(3px);
}*/
@media (max-width: 768px) {
  .images-list {
    margin: -1.5%;
  }
  .images-list .box-margins {
    margin: 1.5%;
  }
  .images-list img {
    width: 30.3%;
    margin: 1.5%;
  }
}
@media (max-width: 480px) {
  .images-list {
    margin: -2%;
  }
  .images-list .box-margins {
    margin: 2%;
  }
  .images-list img {
    width: 46%;
    margin: 2%;
  }
}
@media (max-width: 360px) {
	.images-list {
    margin: -2%;
  }
  .images-list .box-margins {
    margin: 2%;
  }
  .images-list img {
    width: 96%;
    margin: 2%;
  }
} 

</style>