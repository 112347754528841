<template>
  <main id="body-content" class="espaciado">
    <div class="container pos-rel">
      <div class="row">
        <div class="col s12 m8 l6">
          <img :src="img" alt="404 not fount" id="img404">
        </div>
        <div class="col s12 m8 l6 text404">
          <h2 class="text4042 sub-titulo">
              <span class="orange-text text-accent-4">{{ $t('notfound.title1') }}</span>
              <span class="orange-text text-accent-4">{{ $t('notfound.title2') }}</span>
            </h2>
          <p>{{ $t('notfound.text') }}</p>
          <a href="" @click.prevent="$router.push({name: 'home'})" class="form-btn mx-auto btn-theme bg-orange">{{ $t('notfound.button') }}</a>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "NotFound",
  data() {
    return {
      img: "/static/images/etc/404.png"
    };
  }
};
</script>
<style>
.espaciado{
  padding-top: 80px;
}
#img404 {
  width: 90%;
}
.text404 {
 margin-top: 50px;
 display: inline-table;
 height: 100%;

}
.text404{
  vertical-align: middle;
  display: table-cell !important;
}
</style>