import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: window.localStorage.getItem('_language') ? window.localStorage.getItem('_language') : 'es',
    content: {
      gallery: [],
      contacts: {},
      es: {
        notices: {},
        services: {},
        faq: {},
        "el-puerto": {}
      },
      en: {
        notices: {},
        services: {},
        faq: {},
        "el-puerto": {}
      },
      clients: []
    },
    splashScreen: true,
    apiURL: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8000/' : 'https://puertosegurofluvial.com/api_public/',
		appURL: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8080/' : 'https://puertosegurofluvial.com/',
  },
  mutations: {
    setLanguage: (state, payload) => {
      window.localStorage.setItem("_language", payload);
      state.language = payload;
    },
    setContent: (state, payload) => {
      state.content = payload;
    },
    setSplashScreen(state, payload) {
			state.splashScreen = payload
    }
  },
  actions: {
    getContent: async ({commit}) => {
      try {
        const {data} = await Vue.axios({
          method: 'GET',
          url: 'api/v1/all/content'
        });
        if(data.status === 'ok'){
          commit('setContent', data.data)
        }
      } catch (error) {
        return error;
      }
    },
    sendContact: async(context, contact) => {
      try {
        const {data} = await Vue.axios({
          method: 'POST',
          url: 'api/v1/all/contact',
          data: {contact}
        });
        
        return data;

      } catch (error) {
        return error;
      }
    },
    getNotice: async(context, payload) => {
      try {
        const {data} = await Vue.axios({
          method: 'GET',
          url: `api/v1/all/notice/${payload.lang}/${payload.key}`
        });
        
        return data;

      } catch (error) {
        return error;
      }
    }
  },
  getters: {
    splashScreen: state => state.splashScreen,
    language: state => state.language,
    content: state => state.content
  }
})
