<template>
  <footer class="page-footer white">
    <a
      href="https://wa.me/595994481388?text=Hola!%20estoy%20contactando%20desde%20la%20web"
      target="_blank"
      class="btn-floating btn-large waves-effect waves-light green"
      id="boton-flotante"
      ><img src="/static/images/etc/whatsapp.png" alt="icono de whatsapp"
    /></a>
    <div class="container">
      <div class="row">
        <div class="col s12 m2 l2">
          <img
            src="/static/images/logo/logo-original.png"
            alt="logo puerto seguro"
          />
          <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
            <symbol id="linkedin-logo" viewBox="0 0 510 510">
              <title>linkedin-logo</title>
              <path
                d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9 S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204 V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"
              />
            </symbol>
            <symbol id="facebook" viewBox="0 0 512 512">
              <title>facebook</title>
              <path
                d="M452,0H60C26.916,0,0,26.916,0,60v392c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V60 C512,26.916,485.084,0,452,0z M472,452c0,11.028-8.972,20-20,20H338V309h61.79L410,247h-72v-43c0-16.975,13.025-30,30-30h41v-62 h-41c-50.923,0-91.978,41.25-91.978,92.174V247H216v62h60.022v163H60c-11.028,0-20-8.972-20-20V60c0-11.028,8.972-20,20-20h392 c11.028,0,20,8.972,20,20V452z"
              />
            </symbol>
            <symbol id="instagram-logo" viewBox="0 0 169.063 169.063">
              <title>instagram-logo</title>
              <path
                d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752 c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407 c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752 c17.455,0,31.656,14.201,31.656,31.655V122.407z"
              />
              <path
                d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561 C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561 c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
              />
              <path
                d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78 c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78 C135.661,29.421,132.821,28.251,129.921,28.251z"
              />
            </symbol>
         </svg>
          <ul class="redes">
            <li v-for="(item, i) in social" :key="i">
              <a :href="item.link" target="_blank">
                <svg class="icon">
                  <use :xlink:href="item.name" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="col s12 m4 l4">
          <div class="col s12 links">
            <a :href="_contacts.address.link" target="_blank" class="left-align"
              >{{ $t(_contacts.address.label) }}<br
            /></a>
          </div>
          <div class="col s12 links" v-if="_contacts.phones.length > 0">
            <a
              :href="_contacts.phones[0].link"
              target="_blank"
              class="left-align"
              >{{ $t(_contacts.phones[0].label) }}</a
            >
          </div>
          <!-- <div class="col s12 links" v-if="_contacts.emails.length > 0">
            <a :href="_contacts.emails[0].link" target="_blank" class="left-align">{{ $t(_contacts.emails[0].label) }}</a>
          </div> -->
        </div>
        <div class="col s12 m3 l4">
          <div class="col s12 links" v-if="_contacts.emails.length > 0">
            <a
              :href="_contacts.emails[0].link"
              target="_blank"
              class="left-align"
              >{{ $t(_contacts.emails[0].label) }}</a
            >
          </div>
          <!-- <div class="col s12 links">
           <a href="" @click.prevent="goToPolitica()">{{ $t('politica.title') }}</a>
           </div>-->
          <div class="col s12 links">
            <a
              :href="_contacts.company.link"
              target="_blank"
              class="left-align"
              >{{ $t(_contacts.company.label) }}</a
            >
          </div>
        </div>
        <div class="col s12 m3 l2 right-align">
          <a href="https://www.sgs.com/en/certified-clients-and-products/certified-client-directory" target="_blank">
           <img
            src="/static/images/etc/Logo ISO_9001 trasparente.png"
            alt="logo ISO"
            id="iso-logo"
          />
          </a>
        </div>
      </div>
    </div>
    <div class="footer-copyright grey-text text-darken-2">
      <div class="container">
        <span class="left"
          >{{ date.getFullYear() }} - Puerto Seguro Fluvial S.A.<br
        /></span>
        <span class="right"
          >{{ $t("footer.developed") }}
          <a class="blue-text text-accent-4right" href="https://imago.com.py"
            >IMAGO</a
          ></span
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date(),
      social: [
        {
          name: "#instagram-logo",
          link: "https://www.instagram.com/puertosegurofluvial/",
        },
        {
          name: "#facebook",
          link: "https://www.facebook.com/puertosegurofluvial/",
        },
        {
          name: "#linkedin-logo",
          link: "  https://www.linkedin.com/company/puertosegurofluvial/",
        },
      ],
    };
  },
  computed: {
    _contacts() {
      const contacts = this.$store.getters["content"].contacts;
      const data = {
        address: {
          link: "https://goo.gl/maps/TqEH5TCHH2AURubF8",
          label: "footer.address",
        },
        phones: [],
        emails: [],
        // schedules: [],
        company: { link: "https://www.puertoseguro.com.py/", label: "company" },
      };
      if (contacts) {
        Object.keys(contacts).forEach((k) => {
          switch (contacts[k].type) {
            case "email":
              data.emails.push({
                link: `mailto:${contacts[k].detail}`,
                label: "footer.send_email",
              });
              break;
            case "number":
              data.phones.push({
                link: `tel:${contacts[k].detail}`,
                label: "footer.call_center",
              });
              break;
            // case 'address':
            //   data.emails.push({
            //     link: `mailto:${contacts[k].detail}`,
            //     label: 'footer.send_email'
            //   });
            //   break;
            // case 'schedule':
            //   data.emails.push({
            //     link: `mailto:${contacts[k].detail}`,
            //     label: 'footer.send_email'
            //   });
            //   break;
            // default:
            //   break;
          }
        });
      }
      return data;
    },
  },
  methods: {
    goToPolitica() {
      this.$router.push({ name: "politica" });
    },
  },
};
</script>
