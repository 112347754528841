<template>
  <span>
    <section id="home">
      <div class="video-wrapper">
        <div class="video-player">
          <video
            loop
            muted
            autoplay
            poster="/static/images/bg/home-bg.jpg"
            class
            width="1920"
            height="1280"
          >
            <source src="/static/video/home-bg.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col s12 m8 l6 offset-m4 offset-l6 right-align">
            <div id="heroTitle">
              <h1 class="white-text">{{ $t('home.first_title') }}</h1>
              <a
                href
                @click.prevent="goToServices()"
                class="waves-effect waves-light boton orange accent-4 white-text"
                data-wow-delay="3.1s"
              >{{ $t('home.first_button') }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <servicios></servicios>
    <contador></contador>
    <clientes></clientes>
    <contacto></contacto>
    <notices></notices>
    <galeria></galeria>
  </span>
</template>
<script>
import Servicios from "@/components/home/Servicios.vue";
import Contador from "@/components/home/Contador.vue";
import Clientes from "@/components/home/Clientes.vue";
import Contacto from "@/components/home/Contacto.vue";
import Notices from "@/components/home/Notices.vue";
import Galeria from "@/components/home/Galeria.vue";

export default {
  name: "Home",
  components: { Servicios, Contador, Clientes, Contacto, Notices, Galeria },
  data() {
    return {
      header: []
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.header = [
      document.getElementById("nav"),
      document.getElementById("nav-child")
    ];
  },
  methods: {
    goToServices() {
      this.$router.push({ name: "services" });
    },
    handleScroll(event) {
      const heightHome = document.getElementById("home").offsetHeight;
      const currentHeight = document.documentElement.scrollTop;
      if (currentHeight > heightHome - 84) {
        for (let i = 0; i < this.header.length; i++) {
          const element = this.header[i];
          element.classList.add("nav-bg-2");
          element.classList.remove("nav-bg");
        }
      } else {
        for (let i = 0; i < this.header.length; i++) {
          const element = this.header[i];
          element.classList.remove("nav-bg-2");
          element.classList.add("nav-bg");
        }
      }
    }
  }
};

// var min_w = 300;
// var vid_w_orig;
// var vid_h_orig;

// $(function() {

//     vid_w_orig = parseInt($('video').attr('width'));
//     vid_h_orig = parseInt($('video').attr('height'));

//     $(window).resize(function () { fitVideo(); });
//     $(window).trigger('resize');

// });

// function fitVideo() {

//     $('#video-viewport').width($('.fullsize-video-bg').width());
//     $('#video-viewport').height($('.fullsize-video-bg').height());

//     var scale_h = $('.fullsize-video-bg').width() / vid_w_orig;
//     var scale_v = $('.fullsize-video-bg').height() / vid_h_orig;
//     var scale = scale_h > scale_v ? scale_h : scale_v;

//     if (scale * vid_w_orig < min_w) {scale = min_w / vid_w_orig;};

//     $('video').width(scale * vid_w_orig);
//     $('video').height(scale * vid_h_orig);

//     $('#video-viewport').scrollLeft(($('video').width() - $('.fullsize-video-bg').width()) / 2);
//     $('#video-viewport').scrollTop(($('video').height() - $('.fullsize-video-bg').height()) / 2);

// };
</script>
<style>

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.video-wrapper .video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-wrapper .video-player video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
/* 
#video-viewport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
video {
  display: block;
  width: 100%;
  height: auto;
}

.fullsize-video-bg {
  height: 100%;
  overflow: hidden;
}


.fullsize-video-bg .inner {
  display: table;
  width: 100%;
  max-width: 24em;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 2;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}
.fullsize-video-bg .inner > div {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 0 2em;
} */
</style>
