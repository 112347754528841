<template>
  <div class="card">
    <div class="card-image" :style="{
      background: `url('${img.replace('\\', '/')}') center center no-repeat`
    }">
      <!-- <img :src="img" /> -->
      <span class="card-title truncate">{{ title }}</span>
    </div>
    <div class="card-content">
      <p>{{ description }}</p>
    </div>
    <div class="card-action">
      <a :href="`${$store.state.appURL}contenido-noticia?lang=${lang}&key=${id}`">{{ $t('notices.see_more') }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Notice-Card',
  props: ['title', 'description', 'img', 'id'],
  computed: {
    lang(){
      return this.$store.getters['language'];
    }
  },
  methods: {
    goTo(link){
      // this.$router.push(
      //   {
      //     name:'post', 
      //     params: {
      //       title: this.formatTitle(this.title),
      //       key: this.id
      //     }
      //   });

    },
    formatTitle(title){
      const mapObj = {
        ' ': '_',
        '/': '_',
        '?': '',
        '\\': '_'
      };
      return title.replace(/ |\/|\\|\?/gi, matched => mapObj[matched]); 
    }
  }
}
</script>
