<template>
  <div>
    <div id="main-service">
      <div class="container">
        <div class="row">
          <div class="col s12 left-align">
            <h2 class="sub-titulo white-text">{{ $t('services.title') }}</h2>
            <p class="sub-text white-text">{{ $t('services.description') }}</p>
          </div>
        </div>
      </div>
    </div>
    <section id="service-detail" class="grey lighten-5">
      <div class="container">
        <div class="row center-align">
          <div class="col s12">
            <h2 class="sub-titulo orange-text text-accent-4 left-align">{{ $t('services.subtitle') }}</h2>
          </div>
          <div class="col s12 m6 l4" v-for="(item,i) in _services" :key="i">
            <a href="#contenServices" @click="detail(item)" v-smooth-scroll>
              <div class="card center-align hoverable">
                <div class="card-image">
                  <img :src="`${$store.state.apiURL}${item.img}`" :alt="item.title" />
                </div>

                <div class="card-content">
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!--<section id="preguntas-frecuentes">
      <div class="container">
        <h2 class="sub-titulo orange-text text-accent-4">{{ $t('services.faq_title') }}</h2>
        <div class="row">
          <div class="col s12 m12 l6" v-for="(item, i) in _faq" :key="i">
            <h3>{{ item.question }}</h3>
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>
    </section>-->
    
    <section id="contenServices" class="scrollspy" v-show="showDetail">
      <div class="container">
        <div class="col s12 right-align">
          <button @click="showDetail = false" class="waves-effect waves-teal btn-flat center-align hoverable">
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
      <div class="container">
        <h2 class="sub-titulo orange-text text-accent-4">{{ service_selected.title }}</h2>
        <div class="row">
          <div class="col s12 m12 l6">
            <img
              :src="service_selected.img"
              alt
              class="responsive-img"
            />
          </div>
          <div class="col s12 m12 l6">
            <p>{{ service_selected.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Services",
  data() {
    return {
      service_selected: {
        title: '',
        description: '',
        img: ''
      },
      showDetail: false
    };
  },
  methods: {
    detail(service){
      this.service_selected = {
        title: service.title,
        description: service.description,
        img: service.background
      }
      this.showDetail = true;
    }
  },
  computed: {
    _services() {
      const lang = this.$store.getters["language"];
      this.showDetail = false;
      const services = this.$store.getters["content"][lang].services;
      const data = [];
      let i = 1;
      if (services) {
        Object.keys(services).forEach(k => {
          services[k].delay = `0.5s`;
          services[k].effect = i % 2 === 0 ? "bounceInRight" : "bounceInLeft";
          i++;
          data.push(services[k]);
        });
      }
      return data;
    },
    _faq() {
      const lang = this.$store.getters["language"];
      const faq = this.$store.getters["content"][lang].faq;
      const data = [];
      if (faq) {
        Object.keys(faq).forEach(k => {
          data.push(faq[k]);
        });
      }
      return data;
    }
  }
};
</script>
<style>
.card {
  cursor: pointer;
  transition: 0.5s;
}

.card-image {
  text-align: center !important;
  height: 126px;
}
.card-image img {
  width: 100px !important;
  display: inline-block !important;
  padding-top: 20px;
}
.card-content{
  height: 110px;
}
.card-content h3 {
  margin-top: 0px;
  transition: 0.5s;
}
#service-detail h2 {
  font-size: 22px;
}
#service-detail .card h3 {
  color: #ff6d00;
  font-size: 20px;
  font-weight: 400;
}
</style>
