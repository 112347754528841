import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification'
import i18n_messages from './i18n.json'
import VueI18n from 'vue-i18n'
import VueMasonry from 'vue-masonry-css';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueMeta from 'vue-meta';
// import vuescroll from 'vuescroll';
import vueSmoothScroll from 'vue-smooth-scroll'
Vue.use(vueSmoothScroll);

//----------- Vue Scroll
// Vue.use(vuescroll, {
//   ops: {
//     // The global config
//   }
// });
//----------- Vue Scroll


//----------- Vue Meta
Vue.use(VueMeta);
//----------- Vue Meta

//----------- Vue Axios
axios.defaults.baseURL = store.state.apiURL;
Vue.use(VueAxios, axios);
//----------- Vue Axios

//----------- Notification
Vue.use(Notifications);
//----------- Notification

//------Filtros
Vue.filter('toUpperCaseWords', text => {
	const words = text.toLowerCase().split(' ');
	return words.map(word => word.charAt(0).toUpperCase()+word.slice(1)).join(' ');
})
Vue.filter('separator', (value, separator='.') => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
})
//------//

//----------- i18n (Language)
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.state.language,
  messages: i18n_messages });
//----------- i18n (Language)

//----------- Masonry
Vue.use(VueMasonry);
//----------- Masonry

//----------- v-viewer
Vue.use(Viewer);
//----------- v-viewer

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')