<template>
  <div>
    <section class="contenido politica">
      <div class="container">
        <div class="row">
          <h2 class="sub-titulo left-align orange-text text-accent-4">{{ $t('politica.title') }}</h2>
          <div class="col s12">
              <p>{{ $t('politica.description') }}</p>
            <ul>
                <li>{{ $t('politica.p1') }}</li>
               <li>{{ $t('politica.p2') }}</li>
               <li>{{ $t('politica.p3') }}</li>
               <li>{{ $t('politica.p4') }}</li>
               <li>{{ $t('politica.p5') }}</li>
               <li>{{ $t('politica.p6') }}</li>
      

            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Politica",
  data() {
    return {
      
    };
  },
  computed: {
    _aboutUs() {
      const lang = this.$store.getters["language"];
      return this.$store.getters["content"][lang]["el-puerto"];
    }
  }
};
</script>
<style>
    .politica{
        padding: 150px 0px 100px 0px;
    }
    .politica p,  .politica ul {
        margin-top: 40px;
    line-height: 30px;
    font-size: 17px;
    }
    .politica ul {
        list-style: disc !important;
    }
    .politica li{
        list-style-type: disc !important;
        margin-left: 30px;
    }
   
</style>
