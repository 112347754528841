<template>
  <section id="clientes">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <h2 class="sub-titulo orange-text text-accent-4 left-align">{{ $t('home.clients_title') }}</h2>
        </div>
        <div class="carousel" v-if="_clients.length > 0">
          <carousel :items="4" :margin="50" :nav="false" :autoplay="true" :dots="false" :loop="true">
            <a v-for="(item,i) in _clients" :key="i" :href="item.link">
              <img :src="`${$store.state.apiURL}${item.url}`" />
            </a>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Carousel from 'vue-owl-carousel';

export default {
  name: "Home-Clientes",
  components: {Carousel},
  data() {
    return {
      list: [
        {img:'/static/images/containers/c1.jpg', link: '#'},
        {img:'/static/images/containers/c2.jpg', link: '#'},
        {img:'/static/images/containers/c3.jpg', link: '#'},
        {img:'/static/images/containers/c4.jpg', link: '#'},
        {img:'/static/images/containers/c5.jpg', link: '#'}
      ]
    };
  },
  computed: {
    _clients() {
      const clients = this.$store.getters["content"].clients;
      const data = [];
      if (clients) {
        Object.keys(clients).forEach(k => {
          clients[k].link = '#';
          data.push(clients[k]);
        });
      }
      return data;
    }
  }
};
</script>
