<template>
  <section id="contact">
    <div class="container">
      <loading :active.sync="isLoading" color="#fd7e14"></loading>
      <div class="row">
        <div class="boat animation animate"></div>
        <div class="col s12 left-align">
          <h2 class="sub-titulo white-text">{{ $t('contacts.title') }}</h2>
        </div>
        <form class="col s12 m10 l8 left-align" @submit.prevent="sendForm">
          <div class="input-field col s12 m4 l4">
            <input :placeholder="$t('contacts.form.name')" id="nombre_completo" v-model="form.name" type="text" class="validate" />
            <span class="form-error">{{ $t(errors.name) }}</span>
          </div>
          <div class="input-field col s12 m4 l4">
            <input :placeholder="$t('contacts.form.phone')" id="telefono" type="tel" class="validate" v-model="form.tel"/>
            <span v-if="errors.tel.length > 0" class="form-error">{{ $t(errors.tel)}}</span>
          </div>
          <div class="input-field col s12 m4 l4">
            <input :placeholder="$t('contacts.form.email')" id="email" type="email" class="validate" v-model="form.email"/>
            <span class="form-error">{{ $t(errors.email) }}</span>
          </div>
          <div class="input-field col s12 m8 l8">
            <textarea id="mensaje" class="materialize-textarea" :placeholder="$t('contacts.form.message')" v-model="form.message"></textarea>
            <span class="form-error">{{ $t(errors.message) }}</span>
          </div>
          <div class="input-field col s12 m4 l4">
            <button type="submit" class="boton waves-effect waves-light">{{ $t('contacts.form.button') }}</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "Home-Contacto",
  components: {Loading},
  data() {
    return {
      isLoading: false,
      form: {
        name: '',
        tel: '',
        email: '',
        message: ''
      },
      errors: {
        name: '',
        tel: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async sendForm(e){

      // e.preventDefault();

      let error = false;

      if(this.form.name.length === 0){
        error = true;
        this.errors.name = 'contacts.validation.name'
      }
      if(this.form.email.length === 0){
        error = true;
        this.errors.email = 'contacts.validation.email_empty';
      }else{
        if(!this.validateEmail(this.form.email)){
          error = true;
          this.errors.email = 'contacts.validation.email_invalid';
        }
      }
      if(this.form.tel.length === 0){
        error = true;
        this.errors.tel = 'contacts.validation.phone';
      }
      if(this.form.message.length === 0){
        error = true;
        this.errors.message = 'contacts.validation.message';
      }

      setTimeout(() => {
        this.errors.name = '';
        this.errors.email = '';
        this.errors.message = '';
        this.errors.tel = ''
      }, 3000);

      if(!error){
        try {
          this.isLoading = true;
          const res = await this.$store.dispatch('sendContact', this.form);
          this.isLoading = false;
          if(res.status && res.status === 'ok'){
            this.$notify({
              group: 'form-message',
              type: 'success',
              title: this.$t('notification.form_message.success_title'),
              text: this.$t('notification.form_message.success_description'),
              duration: 4000
            });
            this.form = {
              name: '',
              email: '',
              message: ''
            }
          }else{
            this.$notify({
              group: 'form-message',
              type: 'error',
              title: this.$t('notification.form_message.error_title'),
              text: this.$t('notification.form_message.error_description'),
              duration: 4000
            });
          }
        } catch (error) {
          this.$notify({
            group: 'form-message',
            type: 'error',
            title: this.$t('notification.form_message.error_title'),
            text: this.$t('notification.form_message.error_description'),
            duration: 4000
          });
        }
        
      }
    }
  }
};
</script>
<style>
  .form-error{
    width: 100%;
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0px 1px;
    color: #ffffff;
    font-size: 12px; 
  }
</style>
