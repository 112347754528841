<template>
  <section id="servicios">
    <div class="container">
      <div class="row">
        <div class="col s12 m8 offset-m2 l3 left-align wow bounceInLeft" data-wow-delay=".5s">
          <h2 class="sub-titulo orange-text text-accent-4">{{ $t('home.services_title') }}</h2>
          <p class="sub-text grey-text text-darken-4 mt-60">
            {{ $t('home.services_description', {years}) }}
          </p>
        </div>
        <div class="col l2"></div>
        <div class="col s12 m12 l7 grey-text text-darken-4" id="lista-servicios">
          <div v-for="(item, i) in _services" :key="i" 
            :class="`col s6 m4 l4 left-align wow ${item.effect}`" 
            :data-wow-delay="item.delay"
          >
            <img :src="`${$store.state.apiURL}${item.img}`" :alt="item.title" />
            <p>{{ item.title }}</p>
          </div>
        </div>
        <div class="col s12 center-align">
          <a href="" @click.prevent="goToServices()" class="waves-effect waves-light boton boton-orange transparent">{{ $t('home.services_button') }}</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Home-Servicios',
  data(){
    const date = new Date();
    return {
      years: date.getFullYear() - 2012
    }
  },
  computed: {
    _services() {
      const lang = this.$store.getters["language"];
      const services = this.$store.getters["content"][lang].services;
      const data = [];
      let i = 1;
      if (services) {
        Object.keys(services).forEach(k => {
          services[k].delay = `0.5s`;
          services[k].effect = (i % 2 === 0) ? 'bounceInRight' : 'bounceInLeft';
          i++;
          data.push(services[k]);
        });
      }
      return data;
    }
  },
  methods: {
    goToServices(){
      this.$router.push({name: 'services'})
    }
  }
}
</script>
<style>

#lista-servicios div{
  height: 200px;
}
#lista-servicios{
  margin-bottom: 40px;
}
</style>
